
:root {
	--primary-color-blue: #1C4293;
	--primary-color-yellow: #FFD100;
	--primary-color-red: #F42434;
	--primary-color-black: #212529;

	--secondary-color-blue: #050D9E;
	--secondary-color-blue-1: #007197;
	--secondary-color-orange: #F3BAD0;
	--secondary-color-red: #F42434;
}