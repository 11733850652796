.floating-react-select .floating-react-select__control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding-top: 20px;
    background-color: transparent;
}

.floating-react-select .floating-react-select__indicators {
    display: none;
}