.multi-step hr {
    height: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -1.3em;
    z-index: -1;
    position: relative;
}

.multi-step-list {
    text-align: center;
    color: #6c757d;
}

.multi-step-list .multi-step-item {
    padding-left: 0;
    padding-right: 0;
}

.multi-step .active {
    font-weight: bold;
    color: black;
}

.multi-step-list .multi-step-label {
    margin-top: .5em;
}

.multi-step-list .multi-step-circle {
    background-color: #f2f2f2;
    border-radius: 50%;
    width: 2.3em;
    height: 2.3em;
    line-height: 1.9em;
    text-align: center;
    border: 3px solid;
    margin: auto;
}

.multi-step-list .active .multi-step-circle{
    background-color: white;
}


.multi-step .multi-step-list .completed hr {
    color: black;
    opacity: 1;
}

.multi-step .multi-step-list .active hr {
    color: inherit;
    opacity: 1;
}