button.field-array-remove{
    height:100%;
}

.field-array {
    margin-left: 20px;
}
ul.field-array-list {
    list-style: none;
    margin-left: 0;
}
.field-array-item-fields {
    background-color: #cdcecd38;
    border-radius: 5px 5px;
    padding: 5px;
}
.field-array-item-fields:hover {
    background-color: #cdcecd70;
}