@import './variables.css';
@import '../components/common/NavInPage.css';
@import './forms.css';
@import 'field_array.css';
@import 'mobilities.css';
@import 'floating-react-select.css';
@import 'multi-step.css';
@import '../components/student/SidebarContent.css';

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

/*h3, h4, h5, h6 {
	color: var(--primary-color-blue);
}*/

a {
	color: var(--primary-color-blue);
}

.is-invalid~.invalid-feedback, 
.is-invalid~.invalid-tooltip, 
.was-validated :invalid~.invalid-feedback, 
.was-validated :invalid~.invalid-tooltip {
  display: block;
}

.mySelect__control.invalid,
.form-control.invalid {
	border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e') !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.mySelect__control.invalid:focus,
.form-control.invalid:focus {
	border-color: #dc3545 !important;
	box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%) !important;
}

.was-validated .mySelect__control {
	padding-right: 4.125rem;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e),url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e');
  background-position: right 0.75rem center,center right 2.25rem;
  background-size: 16px 12px,calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-repeat: no-repeat;
  border-color: #198754;
}

.text-primary {
	color: var(--primary-color-blue) !important;
}

.table > thead:nth-child(2) {
	border-top-width: 1px;
	border-bottom-width: 2px;
	border-bottom-color: var(--primary-color-black);
}

.table > thead {
	border-bottom: 0 solid var(--primary-color-black);
}

.table-bordered > thead > tr {
	border-width: 1px 0 0 0;
}

.table-bordered > thead > tr > th {
	border-width: 1px 1px 0 1px;
}

.table-bordered > tbody {
	border-top: none;
}

.mySelect__value-container{
	height: 58px;
}

.popover-notifications .popover-body {
	padding: 0;
}

.popover-notifications .popover-body span.icon-container {
	background: #ffc0c0;
	padding: .5em;
	border-radius: 50%;
}

.page-footer {
	margin-top: 3em;
	text-align: center;
}

.page-footer ul {
	list-style: none;
	padding-left: 0;
}
.page-footer ul > li {
	display: inline-block;
}
.page-footer ul > li:not(:first-child) {
	padding-left: 1em;
}